import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PageComponent from "./PageComponent";
import { Typography, Paper, Link, Divider } from "@material-ui/core";
import PrivayPolicyComponent from "./PrivacyPolicy";
import BabynamesComponent from "./BabynamesComponent";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: 15,
  },
}));

const pages = [
  {
    name: "Collisio",
    pageUrl: "https://www.collisio.club",
    imageUrl: "../images/collisio2.png",
    cardText:
      "A racing game. Use your phone to steer a car displayed on your computer. I create all the models used in the game.",
  },
  {
    name: "Classic Games",
    pageUrl: "https://www.classicgames.games",
    imageUrl: "../images/classicgames.svg",
    cardText:
      "Simple gaming website me and my friend Bjarki are developing.",
  },
  {
    
    name: "Betra Veður",
    imageUrl: "../images/betra_vedur.png",
    cardText:
      "A app with better UX for mobile than the Icelandic weather website vedur.is. I (any many others) only trust the weather forecast in Iceland from vedur.is. In this app you can see all the forcasts at once and navigating the time is much easier. Available on the App Store and the Play Store.",
  },
  {
    name: "Krossorðaleikur",

    imageUrl: "../images/krossordaleikur.png",
    cardText:
      "A word creating game similar to scrabble. Available on the App Store and the Play Store.",
  },
  {
    name: "Vala Barnanöfn",
    imageUrl: "../images/babynames.png",
    cardText:
      "An app to choose an Icelandic name for your child. Available on the App Store and the Play Store.",
  },
  // {
  //   name: "Fill in the Quote",
  //   pageUrl: "/quote",
  //   imageUrl: "../images/quotes.png",
  //   cardText:
  //     "A simple text game I created to learn to program in the Flutter environment.",
  // },
  {
    name: "Spottaði fugl",
    pageUrl: "https://spottadi-fugl.herokuapp.com/",
    imageUrl: "../images/spottadifugl.PNG",
    cardText:
      "An online bird diary. I received a small grant from the Icelandic ministry of the Environment to develop this site.",
  },
  {
    name: "Bird game",
    pageUrl: "/fuglar",
    imageUrl: "../images/birds.jpg",
    cardText:
      "Another bird related project. Simple game to learn the birds of Iceland. No real point to this project.",
  },
  {
    name: "JeoJuessr",
    pageUrl: "/jeojuessr",
    imageUrl: "../images/jeojuessricon.png",
    cardText:
      "A weekend project of mine, a free version of the game GeoGuessr using open source technologies. It is possible to play with friends.",
  },
  {
    name: "Veður gif",
    pageUrl: "https://twitter.com/vedurgif",
    imageUrl: "../images/vedurgif.PNG",
    cardText:
      "Twitterbot that creates a GIF of the weather predictions of Iceland as their webpage is annoying to navigate on mobile.",
  },
  {
    name: "Utility Functions",
    pageUrl: "https://www.utilityfunctions.com/",
    imageUrl: "../images/utilityfunctions.png",
    cardText:
      "A bunch of utility functions, to help with your everyday needs.",
  },
  // {
  //   name: "Colors",
  //   pageUrl: "/colors",
  //   imageUrl: "../images/colors.PNG",
  // },
  {
    name: "Jón Sigurðsson timeline",
    pageUrl: "https://jonshus.dk/timalina/",
    imageUrl: "../images/jonsig.PNG",
    cardText:
      "Project I did for the government of Iceland. Converting an old adobe flash application to a website.",
  },
];



const FrontPage = () => {
  const classes = useStyles();
  const xsSize = 12;
  const smSize = 4;
  const lgSize = 3;

console.log("href",window.href)
if(window.location.pathname.includes("/privacy-babynames") || window.location.pathname.includes("/privacy-gps")  ){
  const t  = window.location.pathname.includes("babynames") ? "babynames":"SimpleGPS"
  return <PrivayPolicyComponent type={t} />
}

if(window.location.pathname.includes("/barnanofn") ||window.location.pathname.includes("/babynames")){
  return <BabynamesComponent />
}

  return (
    <Grid container className={classes.root} spacing={0} style={{marginBottom:25}}>
 
      {pages.map((page) => (
        <PageComponent
          pageUrl={page.pageUrl}
          imageUrl={page.imageUrl}
          xsSize={xsSize}
          smSize={smSize}
          lgSize={lgSize}
          key={page.pageUrl}
          cardText={page.cardText}
          cardTitle={page.name}
        />
      ))}
        <Grid item xs={12}  />

        <Grid item xs={1} lg={3} />
      <Grid item xs={10} lg={6}>
        <Paper
          style={{
            margin: "auto",

            padding: 15,
            backgroundColor: "#dedede",
            marginTop: 25,
          }}
        >
          <Typography>
            My name is Hugi Holm, I work as a fullstack developer at Kóði. I hold a MSc in Computer Science from the University of Copenhagen.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            I like making random stuff.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            I am interested in Machine Learning, I did my Master's thesis where
            the topic was{" "}
            <Link href="https://en.wikipedia.org/wiki/Generative_adversarial_network">
              GANs
            </Link>
            . I am also interested in frontend development, I worked part-time
            along with my studies as a React developer, and I am interested in
            learning more backend, that is more than throwing together a nodejs
            sever. Most of my studies have focused on computational science,
            algorithms and machine learning.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography>
            On this site are some side-projects I have played with. Most of
            these are on my{" "}
            <Link href="http://github.com/hugisveitti">Github</Link>.
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={1} lg={3} />
    </Grid>
  );
};

export default FrontPage;
